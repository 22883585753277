<template>
  <b-col cols="12" xs="2" sm="6" md="6" lg="4" class="d-flex align-item-center">
    <b-avatar
      v-if="icon != ''"
      :text="label.toString()"
      rounded
      class="mr-2 text-truncate"
      size="50px"
    >
      <slot color="text-truncate" name="img"></slot>
      <feather-icon v-if="icon" :icon="icon" size="40" color="white" />
    </b-avatar>
    <b-avatar
      v-if="icon == ''"
      ref="previewEl"
      :src="src"
      :text="label.toString()"
      :variant="`light-black`"
      size="50px"
      rounded
      class="mr-2 gray-scale"
      style="padding: 5px"
    />
    <b-form-group :label="label" :label-for="label">
      <div :id="label">
        <slot></slot>
      </div>
    </b-form-group>
  </b-col>
</template>

<script>
import { BAvatar, BCol, BFormGroup } from "bootstrap-vue";
export default {
  components: {
    BAvatar,
    BCol,
    BFormGroup,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    src: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style></style>
