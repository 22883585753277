<template>
  <div>
    <div v-if="ingredientLoading" class="d-flex align-items-center">
      <strong>{{ $t("loading") }}...</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!ingredientLoading" class="d-flex flex-column">
      <div class="d-flex justify-content-between">
        <div></div>
        <b-button
          v-b-modal.modalingredients
          class="mb-2 align-self-end"
          variant="outline-primary"
          @click="getRationIngredients"
        >
          {{ $t("ingredientAdd") }}
        </b-button>
      </div>
      <div
        v-if="ration.hammaddeler.length"
        style="margin-left: -22px; margin-right: -22px"
      >
        <b-table-lite
          v-if="true"
          ref="refIngredienttListTable"
          :items="ration.hammaddeler"
          striped
          :small="true"
          :fields="fields"
          responsive
          class="editable-table"
          primary-key="ikey"
          bordered
          show-empty
          :empty-text="$t('noRecords')"
        >
          <!-- Karışım -->
          <template #cell(karisim)="data">
            <div class="text-right">
              <span
                :id="data.item.ikey + '-karisim'"
                :class="
                  data.item.itemtextcolor == 'Green'
                    ? 'text-success'
                    : 'text-danger'
                "
              >
                {{
                  parseFloat(data.item.karisim)
                    .toFixed(ingredientDecimal)
                    .replace(".", ",")
                }}
                <span v-if="data.item.itemtextcolor != 'Green'">
                  <svg
                    v-if="data.item.karisim > data.item.max"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-down"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 5l0 14"></path>
                    <path d="M18 13l-6 6"></path>
                    <path d="M6 13l6 6"></path>
                  </svg>
                  <svg
                    v-if="data.item.karisim <= data.item.min"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-up"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 5l0 14"></path>
                    <path d="M18 11l-6 -6"></path>
                    <path d="M6 11l6 -6"></path>
                  </svg>
                </span>
              </span>
            </div>
          </template>
          <!-- Hammadde Adı -->
          <template #cell(ingredientname)="data">
            {{ data.item.ingredientname }}
          </template>
          <!-- Kod -->
          <template #cell(kod)="data"> {{ data.item.kod }} </template>
          <!-- Min Değer -->
          <template #cell(min)="data">
            <div
              class="editable text-right align-items-center position-relative"
            >
              <feather-icon
                v-if="data.item.min > data.item.max && data.item.max != 0"
                :class="
                  data.item.min > data.item.max && data.item.max != 0
                    ? 'text-danger'
                    : 'text-success'
                "
                class="position-absolute"
                style="right: 0; top: 1px"
                icon="ArrowDownIcon"
              />
              <b-form-input
                :id="data.item.ingredientname + 'min'"
                size="sm"
                style="font-size: 1rem"
                :data-id="'nutrient-' + data.field.key + data.index"
                :value="
                  parseFloat(data.item.min)
                    .toFixed(ingredientDecimal)
                    .replace(',', '.')
                "
                type="number"
                :options="{
                  numeral: true,
                }"
                class="p-relative text-right"
                @change="(v) => (data.item.min = parseFloat(v))"
              />
            </div>
          </template>
          <!-- Max Değer -->
          <template #cell(max)="data">
            <div class="editable text-right">
              <b-form-input
                :id="data.item.ingredientname + 'max'"
                size="sm"
                style="font-size: 1rem"
                :data-id="'ingredient-' + data.field.key + data.index"
                :value="
                  parseFloat(data.item.max)
                    .toFixed(ingredientDecimal)
                    .replace(',', '.')
                "
                class="text-right"
                type="number"
                :options="{
                  numeral: true,
                }"
                @change="(v) => (data.item.max = parseFloat(v))"
              />
            </div>
          </template>

          <template #cell(price)="data">
            <div class="editable text-right">
              <!-- 
                v-click-outside="outside"
                @click="selectColumn(data)"
                <span
                v-if="!selectedColumn(data)"
                class="editable user-select-none"
              >
                {{
                  parseFloat(data.item.price)
                    .toFixed(priceDecimal)
                    .replace(".", ",")
                }}
                v-show="selectedColumn(data)"

              </span> -->
              <b-form-input
                :id="data.item.ingredientname + 'Price'"
                size="sm"
                style="font-size: 1rem"
                :data-id="'ingredient-' + data.field.key + data.index"
                :value="
                  parseFloat(data.item.price)
                    .toFixed(priceDecimal)
                    .replace(',', '.')
                "
                class="text-right"
                type="number"
                :options="{
                  numeral: true,
                }"
                @change="(v) => (data.item.price = parseFloat(v))"
              />
            </div>
          </template>

          <template #cell(delete)="data">
            <b-link @click="removeItem(data.index)">
              <div class="d-flex">
                <feather-icon class="text-danger" icon="Trash2Icon" />
                <small class="d-none d-lg-inline align-middle ml-50 text-dark">
                  {{ $t("delete") }}
                </small>
              </div>
            </b-link>
          </template>
        </b-table-lite>
      </div>
      <b-modal
        id="modalingredients"
        :cancel-title="$t('cancel')"
        :ok-title="$t('add')"
        cancel-variant="outline-secondary"
        scrollable
        @ok="submitIngredient"
      >
        <template #modal-header>
          <div
            class="d-flex"
            style="
              justify-content: space-between;
              width: 100%;
              align-items: center;
            "
          >
            <h3>
              {{ $t("ingredients") }}
            </h3>
            <div style="width: 20px"></div>
            <b-form-group class="w-50 m-0">
              <b-form-input
                v-model="search"
                :placeholder="$t('search')"
                @keyup="changeItem()"
              />
            </b-form-group>
          </div>
        </template>

        <b-card-text
          v-for="(item, index) in allIngredientsSearch"
          :key="index"
          style="overflow-y: auto"
        >
          <b-row>
            <b-col
              :key="index + item.selected"
              cols="10"
              class="d-flex align-items-center"
            >
              <b-form-group
                :label="$t('ingredientName')"
                :label-for="item.id + '-name'"
              >
                <h4
                  :id="item.id + '-name'"
                  class="d-flex"
                  :class="item.product ? 'text-success' : 'text-danger'"
                >
                  {{ item.ingredientname }}
                  <small class="align-self-center ml-1">
                    <b-badge :variant="item.product ? 'success' : 'danger'">{{
                      item.product ? $t("product") : ""
                    }}</b-badge></small
                  >
                </h4>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="d-flex align-items-center">
              <b-form-checkbox
                :key="item.id + '-' + item.selected"
                :checked="item.selected == 1"
                @change.native="changeSelected(item)"
              ></b-form-checkbox>
            </b-col>
            <b-col cols="12"> <hr /> </b-col
          ></b-row>
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCardText,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BBadge,
  BFormCheckbox,
  BSpinner,
  BTableLite,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BButton,
    BCardText,
    BModal,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BBadge,
    BFormCheckbox,
    BSpinner,
    BTableLite,
    BLink,
  },

  data: function () {
    return {
      getUserData: getUserData,
      search: "",
      allIngredientsSearch: [],
      fields: [
        {
          key: "kod",
          label: this.$t("code"),
          class: "kod-col",
        },
        {
          key: "ingredientname",
          label: this.$t("ingredientName"),
          class: "name-col ",
        },
        {
          key: "karisim",
          label: this.$t("mixValue"),
          class: "max-table-col-width ",
          tdClass: "max-input-td-ingredient",
        },
        {
          key: "min",
          label: this.$t("Min"),
          editable: true,
          type: "number",
          tdClass: "max-input-td-ingredient",
        },
        {
          key: "max",
          label: this.$t("Max"),
          editable: true,
          type: "number",
          tdClass: "max-input-td-ingredient",
        },
        {
          key: "price",
          label:
            this.$t("price") + " (" + getUserData().currency + "/Ton" + ")",
          editable: true,
          type: "number",
          tdClass: "max-input-td-ingredient",
        },
        {
          key: "delete",
          label: this.$t("delete"),
          tdClass: "remove-td",
        },
      ],
    };
  },
  computed: {
    allIngredients() {
      return this.$store.state.rationsModule.allIngredients;
    },
    ingredientLoading() {
      return this.$store.state.rationsModule.ingredientLoading;
    },
    ration() {
      return this.$store.state.rationsModule.selectedRation;
    },
    ingredientDecimal() {
      return getUserData().ingredientdecimal;
    },
    priceDecimal() {
      return getUserData().pricedecimal;
    },
  },
  watch: {
    ration: {
      handler: function (val, oldVal) {
        var result = false;
        var names = [];
        for (var i = 0; i < val.hammaddeler.length; i++) {
          if (
            val.hammaddeler[i].max != 0 &&
            val.hammaddeler[i].min > val.hammaddeler[i].max
          ) {
            result = true;
            names.push(val.hammaddeler[i].ingredientname);
          }
        }
        if (result) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("warning"),
              icon: "AlertCircleIcon",
              variant: "warning",
              text:
                this.$t("minValueBiggerThanMax") +
                "." +
                this.$t("ingredients") +
                ": " +
                names.join(", "),
            },
          });
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getRationIngredients();
  },
  methods: {
    removeItem(index) {
      this.ration.hammaddeler.splice(index, 1);
    },
    changeSelected(item) {
      item.selected == 1 ? (item.selected = 0) : (item.selected = 1);
    },
    changeItem() {
      this.allIngredientsSearch = this.allIngredients.filter((x) => {
        return x.ingredientname
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    async getRationIngredients() {
      await this.$store.dispatch("rationsModule/getRationIngredients");
      this.allIngredientsSearch = this.allIngredients;
    },
    async submitIngredient() {
      await this.$store.dispatch("rationsModule/updateIngredients");
      this.search = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.closeButton {
  padding: 0px;
  position: absolute;
  right: 16px;
  top: -5px;
  cursor: pointer;

  :hover {
    color: #b50404;
    transition: all 301ms ease;
  }
}
</style>
<style>
table.editable-table {
  margin: auto;
}

.editable-table .data-cell {
  vertical-align: middle;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.name-col {
  width: 220px;
}

.kod-col {
  width: 50px;
}

.min-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.min-col:hover {
  cursor: pointer;
}
.max-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.max-col .max-col:hover {
  cursor: pointer;
}
.age-col {
  width: 100px;
}

.date-col {
  width: 200px;
}

.is-active-col {
  width: 100px;
}
.editable-table table {
  width: 100%;
  border-collapse: collapse;
}
.editable-table table.b-table-lite {
  width: inherit !important;
  min-width: 1080px;
}
.remove-td {
  min-width: 50px;
  max-width: 75px;
  width: 50px;
}
.max-input-td-ingredient {
  min-width: 120px;
  max-width: 100px;
  width: 120px;
}
</style>
