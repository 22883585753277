<template>
  <div>
    <!-- Action -->
    <b-row>
      <b-col cols="12" sm="4">
        <h4>{{ $t("printRation") }}</h4>
      </b-col>
      <b-col cols="12" sm="4" class="d-flex justify-content-end ml-auto">
        <b-button
          class="d-flex align-items-center"
          variant="outline-primary"
          size="sm"
          @click="printRation()"
        >
          <feather-icon icon="PrinterIcon" size="20" />
          <span class="m-small ml-1">{{ $t("print") }}</span>
        </b-button>
      </b-col>
      <b-col v-if="false" cols="12" sm="4" class="ml-auto">
        <b-button
          class="d-flex align-items-center"
          variant="outline-primary"
          size="sm"
          @click="previewPdf()"
        >
          <feather-icon icon="EyeIcon" size="20" />
          <span class="m-small ml-1">{{ $t("preview") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Info -->
    <b-row class="mt-2">
      <b-col cols="12" sm="6">
        <h6>{{ $t("price") }} ({{ currency }}/Ton)</h6>
        <div
          class="d-flex"
          :class="symbolInFront && 'flex-row-reverse justify-content-end'"
        >
          <span>
            {{
              rationData.rasyon[0].fiyat
                ? parseFloat(rationData.rasyon[0].fiyat).toFixed(priceDecimal)
                : parseFloat(rationData.price).toFixed(priceDecimal)
            }}
          </span>

          <span>
            &nbsp;
            {{ currency }}</span
          >
        </div>
      </b-col>
      <b-col cols="12" sm="6">
        <!-- Report Ext -->
        <b-form-group v-slot="{ ariaDescribedby }" :label="$t('reportExt')">
          <b-form-radio-group
            id="ext-group"
            v-model="printOptions.ext"
            :options="extOption"
            :aria-describedby="ariaDescribedby"
            name="ext-group"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Detailed Options -->
    <b-row v-if="printOptions.type == 'Detay'" class="px-1">
      <b-col cols="12" class="detail">
        <b-form-group
          v-slot="{ ariaDescribedby }"
          :label="$t('detailedOptions')"
        >
          <b-form-checkbox-group
            id="detailed-group"
            v-model="printOptions.detail"
            :options="detailOption"
            :aria-describedby="ariaDescribedby"
            name="detailed-group"
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-row class="mt-1">
      <b-col cols="12" md="6">
        <b-table
          striped
          class="smaller-text"
          :fields="hammaddeTableFields"
          responsive=""
          small
          :items="rationData.hammaddeler"
        />
      </b-col>
      <b-col cols="12" md="6">
        <b-table
          striped
          class="smaller-text"
          responsive=""
          :fields="besinmaddeTableFields"
          small
          :items="rationData.besinmaddeleri"
        >
          <template #cell(print)="{ item }">
            <b-form-checkbox v-model="item.print" />
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormRadioGroup,
  BTable,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BTable,
  },
  data() {
    return {
      blob: null,
      printOptions: {
        type: "Uretim",
        ext: "PDF",
        detail: [
          { showprice: true },
          { showingredientprices: true },
          { showingminmax: true },
          { shownutminmax: true },
        ],
      },
      typeOption: [
        { text: this.$t("feedProdRecipe"), value: "Uretim" },
        { text: this.$t("detailedReport"), value: "Detay" },
        { text: this.$t("analyzeCertificate"), value: "Analiz" },
      ],
      extOption: [
        { text: ".pdf", value: "PDF" },
        { text: ".xlsx (Excel)", value: "EXCEL" },
        // { text: ".docx (Word)", value: "WORD" },
      ],
      detailOption: [
        {
          text: this.$t("printFeedUnitCost"),
          value: {
            showprice: true,
          },
        },
        {
          text: this.$t("printIngPrice"),
          value: { showingredientprices: true },
        },
        {
          text: this.$t("printIngMinMax"),
          value: {
            showingminmax: true,
          },
        },
        { text: this.$t("printNutMinMax"), value: { shownutminmax: true } },
        { text: this.$t("printNotUsedIng"), value: { showunused: true } },
      ],
      hammaddeTableFields: [
        { key: "kod", label: this.$t("code") },
        { key: "ingredientname", label: this.$t("ingredientName") },
        {
          key: "karisim",
          label: this.$t("mix"),
          tdClass: "text-right bg-success text-white",
          formatter: (val) =>
            ((val * this.selectedPlantTonaj) / 1000).toFixed(
              this.ingredientDecimal
            ),
        },
        {
          key: "min",
          label: this.$t("min"),
          tdClass: "text-right",
          formatter: (val) => val.toFixed(this.ingredientDecimal),
        },
        {
          key: "max",
          label: this.$t("max"),
          tdClass: "text-right",
          formatter: (val) => val.toFixed(this.ingredientDecimal),
        },
        {
          key: "price",
          label: this.$t("price"),
          tdClass: "text-right",
          formatter: (val) => val.toFixed(this.priceDecimal) + this.currency,
        },
      ],
      besinmaddeTableFields: [
        { key: "print", label: this.$t("print") },
        { key: "nutrientname", label: this.$t("nutrientName") },
        { key: "birim", label: this.$t("unit") },
        { key: "kod", label: this.$t("code") },
        {
          key: "deger",
          label: this.$t("value"),
          tdClass: "text-right bg-success text-white",
          formatter: (val) => val.toFixed(this.nutrientDecimal),
        },
        {
          key: "min",
          label: this.$t("min"),
          tdClass: "text-right",
          formatter: (val) => val.toFixed(this.nutrientDecimal),
        },
        {
          key: "max",
          label: this.$t("max"),
          tdClass: "text-right",
          formatter: (val) => val.toFixed(this.nutrientDecimal),
        },
      ],
    };
  },
  computed: {
    rationData() {
      return this.$store.state.rationsModule.selectedRation;
    },
    priceDecimal() {
      return getUserData().pricedecimal;
    },
    ingredientDecimal() {
      return getUserData().ingredientdecimal;
    },
    nutrientDecimal() {
      return getUserData().nutrientdecimal;
    },
    currency() {
      return getUserData().currency;
    },
    symbolInFront() {
      return getUserData().symbolinfront;
    },
    selectedPlantTonaj() {
      return 1000;
    },
    plantName() {
      const selectedId = this.$store.state.app.selectedPlantId;
      if (this.$store.state.plantsModule.plantData.length > 0) {
        return (
          this.$store.state.plantsModule.plantData.filter(
            (x) => x.id == selectedId
          )[0]?.plantname ??
          this.$store.state.plantsModule.plantData[0]?.plantname
        );
      } else return "";
    },
  },
  mounted() {
    this.rationData.besinmaddeleri = this.rationData.besinmaddeleri.map(
      (x) => ({ ...x, print: true })
    );
  },
  methods: {
    async previewPdf() {
      if (this.blob) {
        var URL = window.URL || window.webkitURL;
        var downloadUrl = URL.createObjectURL(this.blob);
        const link = document.createElement("a");
        link.href = downloadUrl;

        window.open(downloadUrl);
      }
    },
    async printRation() {
      const localFormulaData = { ...this.rationData };
      await setTimeout(async () => {
        const ration = {
          ...localFormulaData,
          rasyon: [
            {
              price: 0,
            },
          ],
        };
        ration.rasyon[0] = {
          id: localFormulaData.rasyon[0].id,
          companyname: getUserData().companyname,
          plantname: this.plantName,
          code: localFormulaData.rasyon[0].code,
          rationname: localFormulaData.rasyon[0].rationname,
          price: (
            localFormulaData.rasyon[0].fiyat ?? localFormulaData.price
          ).toString(),
          raportipi: this.printOptions.type,
          raporcikti: this.printOptions.ext,
          currency: this.currency,
          showcompanylogo: getUserData().showcompanylogoinreports ?? true,
          showcompanyname: getUserData().showcompanynameinreports ?? true,
          rasyonname: localFormulaData.rasyon[0].rationname,
        };

        if (this.printOptions.type == "Detay") {
          const keys = [
            "showprice",
            "showingredientprices",
            "showingminmax",
            "shownutminmax",
            "showunused",
          ];
          // İstenilen formata uygun hala getiriyorum
          this.printOptions.detail.map((eachDetail) => {
            return Object.entries(eachDetail).map((val) => {
              const key = val[0];
              const value = val[1];
              ration.rasyon[0][key] = value;
            });
          });
          // Eksik keyleri ekliyorum
          keys.map((key) => {
            if (!Object.keys(ration.rasyon[0]).includes(key)) {
              ration.rasyon[0][key] = false;
            }
          });
        }

        this.blob = await this.$store.dispatch(
          "rationsModule/printRation",
          ration
        );
      }, 300);
    },
  },
};
</script>

<style>
.smaller-text {
  font-size: 0.9rem;
}
#type-group,
#ext-group,
.detail {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding-top: 10px;
}
#type-group,
#ext-group {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
