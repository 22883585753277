var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.nutrientLoading)?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('strong',[_vm._v("Loading...")]),_c('b-spinner',{staticClass:"ml-auto"})],1):_vm._e(),(!_vm.nutrientLoading)?_c('div',{staticClass:"d-flex flex-column"},[(_vm.ration.besinmaddeleri.length)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"margin-left":"-20px","margin-right":"-20px"}},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"margin-left":"22px","margin-right":"22px"}},[_c('div'),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalNutrients",modifiers:{"modalNutrients":true}}],staticClass:"mb-2 align-self-end",attrs:{"variant":"outline-primary"},on:{"click":_vm.getRationNutrients}},[_vm._v(" "+_vm._s(_vm.$t("nutrientAdd"))+" ")])],1),_c('b-table-lite',{ref:"refNutrientListTable",staticClass:"editable-table",attrs:{"items":_vm.ration.besinmaddeleri,"striped":"","small":true,"fields":_vm.fields,"responsive":"","primary-key":"nkey","show-empty":"","bordered":"","empty-text":_vm.$t('noRecords')},scopedSlots:_vm._u([{key:"cell(arrow)",fn:function(ref){
var item = ref.item;
return [(
              item.onerilenkarisim != 0 && item.onerilenkarisim < item.karisim
            )?_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"ArrowUpIcon"}}):_vm._e(),(
              item.onerilenkarisim != 0 && item.onerilenkarisim > item.karisim
            )?_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"ArrowDownIcon"}}):_vm._e()]}},{key:"cell(deger)",fn:function(data){return [_c('span',{class:data.item.itemtextcolor == 'Green'
                ? 'text-success'
                : data.item.itemtextcolor == ''
                ? 'text-dark'
                : 'text-danger',attrs:{"id":data.item.nkey + '-deger'}},[_vm._v(" "+_vm._s(parseFloat(data.item.deger) .toFixed(_vm.nutrientDecimal) .replace(".", ","))+" "),(data.item.itemtextcolor != 'Green')?_c('span',[(data.item.deger > data.item.max)?_c('svg',{staticClass:"icon icon-tabler icon-tabler-arrow-down",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M12 5l0 14"}}),_c('path',{attrs:{"d":"M18 13l-6 6"}}),_c('path',{attrs:{"d":"M6 13l6 6"}})]):_vm._e(),(data.item.deger <= data.item.min)?_c('svg',{staticClass:"icon icon-tabler icon-tabler-arrow-up",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M12 5l0 14"}}),_c('path',{attrs:{"d":"M18 11l-6 -6"}}),_c('path',{attrs:{"d":"M6 11l6 -6"}})]):_vm._e()]):_vm._e()])]}},{key:"cell(min)",fn:function(data){return [_c('div',{staticClass:"editable text-right align-items-center position-relative"},[(data.item.min > data.item.max && data.item.max != 0)?_c('feather-icon',{staticClass:"position-absolute",class:data.item.min > data.item.max && data.item.max != 0
                  ? 'text-danger'
                  : 'text-success',staticStyle:{"right":"0","top":"1px"},attrs:{"icon":"ArrowDownIcon"}}):_vm._e(),_c('b-form-input',{staticClass:"text-right",staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'min',"disabled":data.item.nkey == 19 && _vm.$store.state.app.selectedModelId == 2,"size":"sm","data-id":'nutrient-' + data.field.key + data.index,"value":parseFloat(data.item.min)
                  .toFixed(_vm.nutrientDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.min = parseFloat(v)); }}})],1)]}},{key:"cell(max)",fn:function(data){return [_c('div',{staticClass:"editable align-items-center text-right position-relative"},[(data.item.min > data.item.max && data.item.max != 0)?_c('feather-icon',{staticClass:"position-absolute",staticStyle:{"right":"0","top":"1px"},attrs:{"icon":"ArrowUpIcon"}}):_vm._e(),_c('b-form-input',{staticClass:"text-right",staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.nutrientname + 'max',"size":"sm","disabled":data.item.nkey == 19 && _vm.$store.state.app.selectedModelId == 2,"data-id":'nutrient-' + data.field.key + data.index,"value":parseFloat(data.item.max)
                  .toFixed(_vm.nutrientDecimal)
                  .replace(',', '.'),"type":"number","options":{
                numeral: true,
              }},on:{"change":function (v) { return (data.item.max = parseFloat(v)); }}})],1)]}},{key:"cell(delete)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.removeItem(data.index)}}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"Trash2Icon"}}),_c('small',{staticClass:"d-none d-lg-inline align-middle ml-50 text-dark"},[_vm._v(_vm._s(_vm.$t("delete"))+" ")])],1)])]}}],null,false,3088327248)}),_c('b-modal',{attrs:{"id":"modalNutrients","scrollable":"","cancel-title":_vm.$t('close'),"ok-title":_vm.$t('ok'),"cancel-variant":"outline-secondary"},on:{"ok":_vm.submitNutrient},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"space-between","width":"100%","align-items":"center"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("nutrients"))+" ")]),_c('div',{staticStyle:{"width":"20px"}}),_c('b-form-group',{staticClass:"w-50 m-0"},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('search')},on:{"keyup":function($event){return _vm.changeItem()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true}],null,false,1581980982)},_vm._l((_vm.allNutrientsSearch),function(item,index){return _c('b-card-text',{key:index},[_c('b-row',[_c('b-col',{key:index + item.selected,staticClass:"d-flex align-items-center",attrs:{"cols":"10"}},[_c('b-form-group',{attrs:{"label":_vm.$t('nutrientName'),"label-for":item.id + '-name'}},[_c('h4',{staticClass:"d-flex",class:item.product ? 'text-success' : 'text-danger',attrs:{"id":item.id + '-name'}},[_vm._v(" "+_vm._s(item.nutrientname)+" ")])])],1),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"2"}},[_c('b-form-checkbox',{attrs:{"checked":item.selected == 1},on:{"change":function($event){return _vm.changeSelected(item)}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)],1)}),1)],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }