<template>
  <div>
    <div v-if="rationLoading" class="d-flex align-items-center">
      <strong>Loading...</strong>
      <b-spinner class="ml-auto" />
    </div>
    <div v-if="!rationLoading">
      <b-card>
        <!-- Media -->
        <div class="d-flex justify-content-between flex-column flex-lg-row">
          <div id="rationInfo" class="">
            <ration-info-single-item
              :label="rationData.rationname + ' ' + $t('rationWSuffix')"
              icon="CalendarIcon"
            >
              {{ dayjs(rationData.tarih).format("DD.MM.YYYY") }}
            </ration-info-single-item>

            <ration-info-single-item label=" ">
              <template v-slot:img>
                <span style="font-size: 24px">
                  {{ getUserData().currency }}
                </span>
              </template>
              <div class="d-flex justify-content-center align-items-center">
                <h6>
                  {{
                    rationPrice == null
                      ? "0"
                      : parseFloat(rationPrice).toFixed(
                          getUserData().pricedecimal
                        )
                  }}{{ getUserData().currency }}
                </h6>
              </div>
            </ration-info-single-item>
          </div>

          <div>
            <h3>
              <b-badge
                v-if="cozdu == null"
                size="md"
                variant="warning"
                class="d-flex align-items-center justify-content-center w-100"
              >
                <div class="d-flex mr-1">
                  <feather-icon class="text-white" icon="MinusCircleIcon" />
                </div>
                <span class="text-white h5 p-0 m-0">
                  {{ $t("lastSolution") }}</span
                >
              </b-badge>
              <b-badge
                v-if="cozdu != null && !cozdu"
                variant="danger"
                class="d-flex align-items-center justify-content-center w-100"
              >
                <div class="d-flex mr-1">
                  <feather-icon icon="XCircleIcon" />
                </div>
                <span class="text-white h5 p-0 m-0">
                  {{ $t("notSolved") }}
                </span>
              </b-badge>
              <b-badge
                v-if="cozdu"
                variant="success"
                class="d-flex align-items-center justify-content-center w-100"
              >
                <div class="d-flex mr-1">
                  <feather-icon icon="CheckCircleIcon" />
                </div>
                <span class="text-white h5 p-0 m-0">
                  {{ $t("solved") }}
                </span>
              </b-badge>
            </h3>
          </div>
          <div
            class="d-flex flex-wrap mb-2 justify-content-between"
            style="height: 35px"
          >
            <b-button
              class="ml-1"
              variant="success"
              size="sm"
              @click="solveRation()"
            >
              <component
                :is="require('@/assets/images/icons/optimizeW.svg')"
                color="text-truncate"
                height="22"
              />
            </b-button>
            <b-button
              class="ml-1"
              variant="primary"
              size="sm"
              @click="saveRation()"
            >
              <feather-icon icon="SaveIcon" size="22" />
            </b-button>
            <b-button
              class="ml-1"
              variant="secondary"
              size="sm"
              @click="openModal = !openModal"
            >
              <feather-icon icon="PrinterIcon" size="22" />
            </b-button>
            <b-button
              class="ml-1"
              variant="warning"
              size="sm"
              @click="showInfo()"
            >
              <feather-icon icon="InfoIcon" size="22" />
            </b-button>
            <b-button
              class="ml-1"
              variant="danger"
              size="sm"
              @click="deleteRation()"
            >
              <feather-icon icon="TrashIcon" size="22" />
            </b-button>
          </div>
        </div>

        <b-modal
          id="infoModal"
          ref="infoModal"
          :title="$t('animalInfo')"
          size="lg"
          :ok-title="$t('ok')"
          ok-only
          centered
        >
          <b-card no-body class="min-h-100">
            <!-- User Info: Input Fields -->
            <b-row class="space">
              <ration-info-single-item
                v-if="animalType.find((x) => x.value == rationData.irkid)"
                :label="$t('breed')"
              >
                <template v-slot:img>
                  <BeefSvg color="text-truncate" height="30" />
                </template>
                {{ animalType.find((x) => x.value == rationData.irkid).label }}
              </ration-info-single-item>
              <ration-info-single-item
                icon="FileTextIcon"
                :label="$t('rationModel')"
              >
                {{ rationData.model == 1 ? "NASEM" : "INRA" }}
              </ration-info-single-item>
              <ration-info-single-item v-if="rationData.ca" :label="$t('ca')">
                <template v-slot:img>
                  <BeefSvg color="text-truncate" height="30" />
                </template>
                {{ rationData.ca }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.gcaa"
                :label="$t('gcaa')"
              >
                <template v-slot:img>
                  <KilosSvg color="text-truncate" height="35" />
                </template>
                {{ rationData.gcaa }}
              </ration-info-single-item>
              <ration-info-single-item v-if="rationData.bda" :label="$t('bda')">
                <template v-slot:img>
                  <KilosSvg color="text-truncate" height="35" />
                </template>
                {{ rationData.bda }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.kgs"
                :label="$t('kgs')"
                icon="CalendarIcon"
              >
                {{ rationData.kgs }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.kgg"
                :label="$t('kgg')"
                icon="CalendarIcon"
              >
                {{ rationData.kgg }}
              </ration-info-single-item>

              <ration-info-single-item v-if="rationData.sv" :label="$t('sv')">
                <template v-slot:img>
                  <MilkSvg color="text-truncate" height="40" />
                </template>
                {{ rationData.sv }}
              </ration-info-single-item>

              <ration-info-single-item
                v-if="rationData.sy"
                :label="$t('sy')"
                icon="PercentIcon"
              >
                {{ rationData.sy }}
              </ration-info-single-item>

              <ration-info-single-item
                v-if="rationData.ls"
                :label="$t('ls')"
                icon="BarChartIcon"
              >
                {{ rationData.ls }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.sp"
                :label="$t('sp')"
                icon="PercentIcon"
              >
                {{ rationData.sp }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.dsv"
                :label="$t('dsv') + ' ' + $t('DSV')"
                icon="?"
              >
                {{ rationData.dsv }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.kabayemorani"
                :label="$t('minKaba')"
                icon="PercentIcon"
              >
                {{ rationData.kabayemorani }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.kabayemoranimax"
                :label="$t('maxKaba')"
                icon="PercentIcon"
              >
                {{ rationData.kabayemoranimax }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.hayvantipiid"
                :label="$t('animalType')"
                icon="PieChartIcon"
              >
                {{
                  hayvanTipleri.find((x) => x.value == rationData.hayvantipiid)
                    .label
                }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.mggs"
                :label="$t('mggs')"
                icon="RefreshCwIcon"
              >
                {{ rationData.mggs }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.mye"
                :label="$t('mye')"
                icon="GitMergeIcon"
              >
                {{ rationData.mye }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.shy"
                :label="$t('shy')"
                icon="GitCommitIcon"
              >
                {{ rationData.shy }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.bcs"
                :label="$t('bcs')"
                icon="TrendingUpIcon"
              >
                {{ rationData.bcs }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.sicaklik"
                :label="$t('temperature') + ' ' + $t('C')"
                icon="ThermometerIcon"
              >
                {{ rationData.sicaklik }}
              </ration-info-single-item>

              <!-- Bunlar belli değil -->
              <ration-info-single-item
                v-if="rationData.eca && false"
                :label="$t('eca')"
                icon="CalendarIcon"
              >
                {{ rationData.eca }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.zemin && false"
                :label="$t('environment')"
                icon="CalendarIcon"
              >
                {{ rationData.zemin }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.by && false"
                :label="$t('by')"
                icon="PercentIcon"
              >
                {{ rationData.by }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.gh && false"
                :label="$t('gh')"
                icon="PercentIcon"
              >
                {{ rationData.gh }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.lg && false"
                :label="$t('lg')"
                icon="PercentIcon"
              >
                {{ rationData.lg }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.katyonanyondegeri && false"
                :label="$t('katyonanyondegeri')"
                icon="PercentIcon"
              >
                {{ rationData.katyonanyondegeri }}
              </ration-info-single-item>
              <ration-info-single-item
                v-if="rationData.ilkdogum && false"
                :label="$t('ilkdogum')"
                icon="PercentIcon"
              >
                {{ rationData.ilkdogum }}
              </ration-info-single-item>
            </b-row>
          </b-card>
        </b-modal>

        <b-modal
          id="modalPrint"
          v-model="openModal"
          :title="$t('printOptions')"
          centered
          size="xl"
          hide-footer
          class="modal-lg"
        >
          <print-modal></print-modal>
        </b-modal>
      </b-card>
      <b-card class="mt-1">
        <slot />
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BCard,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import RationInfoSingleItem from "./RationInfoSingleItem.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import BeefSvg from "@/assets/images/icons/beef.svg";
import MilkSvg from "@/assets/images/icons/milk.svg";
import KilosSvg from "@/assets/images/icons/kilos.svg";
import MoneySvg from "@/assets/images/icons/money.svg";
import PrintModal from "./PrintModal.vue";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
export default {
  components: {
    RationInfoSingleItem,
    PrintModal,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BBadge,
    FeatherIcon,
    BSpinner,
    //Svg
    BeefSvg,
    MilkSvg,
    KilosSvg,
    MoneySvg,
  },
  props: {
    rationData: {
      type: Object,
      required: true,
    },
    rationView: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      getUserData: getUserData,
      dayjs,
      openModal: false,
      initialRationData: {},
      hayvanTipleri: [
        { label: this.$t("heifer"), value: 3 },
        { label: this.$t("firstPregnancy"), value: 4 },
        { label: this.$t("inLactation"), value: 5 },
        { label: this.$t("inDry"), value: 6 },
      ],
      animalType: [
        {
          value: 1,
          label: "Holştayn",
        },
        {
          value: 2,
          label: "Montofon",
        },
        {
          value: 3,
          label: "Simental",
        },
        {
          value: 4,
          label: "Jersey",
        },
        {
          value: 5,
          label: "Melez",
        },
        {
          value: 6,
          label: "Diğer",
        },
        {
          value: 7,
          label: "Montbeliard",
        },
        {
          value: 8,
          label: "Yerli Kara",
        },
        {
          value: 9,
          label: "Brangus",
        },
        {
          value: 10,
          label: "Güney Anadolu Kırmızısı",
        },
        {
          value: 11,
          label: "Doğu Anadolu Kırmızısı",
        },
        {
          value: 12,
          label: "Boz",
        },
        {
          value: 13,
          label: "Şarole",
        },
        {
          value: 14,
          label: "Limuzin",
        },
        {
          value: 15,
          label: "Blond Aquitaine",
        },
        {
          value: 16,
          label: "Belçika Mavisi",
        },
      ],
    };
  },
  computed: {
    rationLoading() {
      return this.$store.state.rationsModule.rationLoading;
    },
    cozdu() {
      return this.$store.state.rationsModule.cozdu;
    },

    currentRationData() {
      return this.$store.state.rationsModule.selectedRation;
    },
    rationPrice() {
      return this.rationData.fiyat;
    },
  },
  mounted() {
    this.initialRationData = JSON.parse(JSON.stringify(this.currentRationData));
    setTimeout(() => {
      this.solveRation();
    }, 300);
  },

  methods: {
    solveRation() {
      this.$store.dispatch("rationsModule/solveRation");
    },
    saveRation() {
      this.$store.dispatch("rationsModule/saveRation");

      const oldPrices = this.initialRationData.hammaddeler.map((x) => ({
        id: x.ikey,
        oldPrice: x.price,
        newPrice: this.currentRationData.hammaddeler.find(
          (y) => y.ikey == x.ikey
        ).price,
      }));
      if (!oldPrices.every((x) => x.oldPrice == x.newPrice))
        this.$swal({
          title: this.$t("warning"),
          text: this.$t("doYouWantToChangePrice"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            // update prices
            var ikili = oldPrices
              .filter((x) => x.oldPrice != x.newPrice)
              .map((x) => {
                return {
                  id: x.id,
                  deger: parseFloat(x.newPrice),
                };
              });
            var result = this.$http
              .put(
                `/Ingredients/UpdateIngredientPrices/${this.$store.state.app.selectedPlantId}`,
                JSON.stringify(ikili)
              )
              .then((response) => {});
          }
        });
    },
    showInfo() {
      this.$refs.infoModal.show();
    },
    async deleteRation() {
      await this.$store.dispatch("rationsModule/deleteRation", {
        rationid: this.rationData.id,
        plantid: this.rationData.plantid,
        tur: this.$route.params.tur,
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#price label {
  font-size: 18px;
  font-weight: 500;
}
#price div {
  font-size: 18px;
  font-weight: 500;
}
#rationInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    padding: 0;
  }
}
</style>
